import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_lsactea2jbibskgnll3z3plqxa/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/opt/buildhome/repo/web/middleware/history.global.ts";
import pageview_45global from "/opt/buildhome/repo/web/middleware/pageview.global.ts";
import redirect_45global from "/opt/buildhome/repo/web/middleware/redirect.global.ts";
export const globalMiddleware = [
  validate,
  history_45global,
  pageview_45global,
  redirect_45global
]
export const namedMiddleware = {}